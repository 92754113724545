import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import { DirectUploadDropzone } from "../src/dropzone_controller"
import { findElement, getMetaValue, getSessionStorageValue, removeElement, setSessionStorageValue } from "../utils"
import {
  ErrorType,
  FILE_TYPE_TO_ICON,
  FileStatus,
  NON_RETRYABLE_FILE_STYLES,
  RETRYABLE_ERRORS,
  RETRYABLE_FILE_STYLES,
} from "../utils/constants"
import { createHiddenInput, getFileId } from "../utils/files"
import DocumentListComponentController, { UpsertFileParams } from "./document_list_component_controller"

export const EVENT_ADDING_EXISTING_DOCUMENT_RECORD = "Docupload:existingDocumentAdded"
export const EVENT_FILE_ADDED = "Docupload:documentAdded"
export const EVENT_FILE_REMOVED = "Docupload:documentRemoved"
export const EVENT_SUBMIT_ENABLED = "Docupload:submitEnabled"

export type FileChangeEventDetail = {
  file: Dropzone.DropzoneFile
}

export default class extends Controller {
  static outlets = ["document-list-component"]
  static targets = [
    "container",
    "folderIcon",
    "fileList",
    "fileDrop",
    "fileIcon",
    "fileName",
    "submitButton",
    "smallZone",
    "largeZone",
  ]

  dropZone: Dropzone
  inputTarget: HTMLInputElement

  containerTarget: HTMLElement
  folderIconTarget: HTMLElement
  fileListTarget: HTMLElement
  fileDropTarget: HTMLElement
  fileIconTarget: HTMLElement
  fileNameTarget: HTMLElement
  submitButtonTarget: HTMLButtonElement
  hasSubmitButtonTarget: boolean
  formSubmitButton: HTMLButtonElement
  smallZoneTarget: HTMLElement
  largeZoneTarget: HTMLElement

  static values = {
    cacheFiles: Boolean,
    dontBubbleActionButtonClickEvents: {
      default: false,
      type: Boolean,
    },
    fileListItemDataAttributes: Object,
    useCachedFiles: Boolean,
    required: Boolean,
    sessionStorageKey: String,
    hasCustomIcon: Boolean,
    allowMultipleFiles: {
      default: true,
      type: Boolean,
    },
    eventsOnly: Boolean,
    usedInAttachment: Boolean,
    usingProvidedFileList: Boolean,
  }
  cacheFilesValue: boolean
  fileListItemDataAttributesValue: Record<string, string>
  dontBubbleActionButtonClickEventsValue: boolean
  useCachedFilesValue: boolean
  requiredValue: boolean
  sessionStorageKeyValue: string
  hasCustomIconValue: boolean
  allowMultipleFilesValue: boolean
  eventsOnlyValue: boolean
  usedInAttachmentValue: boolean

  maxFiles: number
  // documentListComponentOutlet: DocumentListComponentController
  documentListComponentOutlets: DocumentListComponentController[]
  hasDocumentListComponentOutlet: boolean

  connect() {
    this.initDropzone()
    this.setFormSubmitButton()

    if (this.cacheFilesValue) {
      this.conditionallyInitFileStorage()
    }

    if (this.useCachedFilesValue) {
      this.loadFilesFromSessionStorage()
    }

    if (this.requiredValue && !this.hasSuccessfulUpload()) {
      this.disableSubmitButton()
    }

    window.addEventListener(EVENT_ADDING_EXISTING_DOCUMENT_RECORD, this.processAddedExistingDocument)
  }

  disconnect() {
    this.dropZone.destroy()

    window.removeEventListener(EVENT_ADDING_EXISTING_DOCUMENT_RECORD, this.processAddedExistingDocument)
  }

  processAddedExistingDocument = (e: CustomEvent) => {
    const file = e.detail
    this.dropZone.files.push(file)
    this.dropZone.emit("addedfile", file)
    this.dropZone.emit("processing", file)
    this.dropZone.emit("success", file)
    this.dropZone.emit("complete", file)
    createHiddenInput(file, this.fileDropTarget.querySelector("input[id^='dropzone-input_']"))
  }

  setFormSubmitButton() {
    if (this.hasSubmitButtonTarget) {
      this.formSubmitButton = this.submitButtonTarget
    } else {
      // implied submit button
      const form = this.element.closest("form")
      if (form != null) {
        this.formSubmitButton = form.querySelector("input[type='submit']")
      }
    }
  }

  initDropzone() {
    this.maxFiles = this.data.get("max-files") ? Number(this.data.get("max-files")) : null
    if (!this.allowMultipleFilesValue) {
      this.maxFiles = 1
    }

    const directUploadDropzone = new DirectUploadDropzone(this.fileDropTarget, {
      headers: { "X-CSRF-Token": getMetaValue("csrf-token") },
      maxFilesize: Number(this.data.get("max-file-size")) || 256,
      maxFiles: this.maxFiles,
      acceptedFiles: this.data.get("accepted-files"),
      addRemoveLinks: this.data.get("add-remove-links") != null || false,
      disablePreviews: true,
      uploadMultiple: this.allowMultipleFilesValue,
      autoQueue: false,
      autoProcessQueue: false,
      maxFilesPerDrop: this.data.get("max-files-per-drop") ? Number(this.data.get("max-files-per-drop")) : null,
    })

    this.dropZone = directUploadDropzone.dropZone
    this.bindEvents()
  }

  conditionallyInitFileStorage(): void {
    const files = getSessionStorageValue(this.sessionStorageKeyValue)
    if (!files) {
      setSessionStorageValue(this.sessionStorageKeyValue, {})
    }
  }

  loadFilesFromSessionStorage(): void {
    const files = Object.values(getSessionStorageValue(this.sessionStorageKeyValue))

    files.forEach((file) => {
      file.name = file.upload.filename

      this.dropZone.files.push(file)
      this.dropZone.emit("addedfile", file)
      this.dropZone.emit("processing", file)
      this.dropZone.emit("success", file)
      this.dropZone.emit("complete", file)
    })
  }

  bindEvents() {
    this.dropZone.on("removedfile", (file: any) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("maxfilesperdropexceeded", () => {
      this.showClosedFolder()
      alert(`You can only upload a maximum of ${this.maxFiles || 0} files at once.`)
    })

    this.dropZone.on("maxfilesexceeded", () => {
      this.showClosedFolder()
      alert(`You can only upload a maximum of ${this.maxFiles || 0} files at once.`)
    })

    this.dropZone.on("processing", (file) => {
      this.updateProvidedFileList({ file, status: FileStatus.LOADING })
      this.createFileListItem(file)
      this.disableSubmitButton()
    })

    this.dropZone.on("success", (file) => {
      this.updateProvidedFileList({ file, status: FileStatus.SUCCESS })
      this.addHiddenFileNameInput(file)
      this.addHiddenTriggerInput(file)

      if (this.cacheFilesValue) {
        this.addFileToCache(file)
      }

      // make the "has files" UI updates if need be
      this.showSmallZone()
      if (!this.hasDocumentListComponentOutlet) {
        // update entry in the file list
        this.updateFileStatus(file, FileStatus.SUCCESS)
        this.addRemoveButton(file)
        this.showFileList()
      }

      // enable the submit button now that we have at least one file, if no others are still uploading
      if (!this.isUploading()) {
        this.enableSubmitButton()
      }

      window.dispatchEvent(new CustomEvent<FileChangeEventDetail>(EVENT_FILE_ADDED, { detail: { file } }))
    })

    this.dropZone.on("error", (file, error) => {
      const fullError = error ? this.transformError(error) : null

      this.updateProvidedFileList({
        file,
        status: FileStatus.ERROR,
        statusLabel: this.getErrorTag(fullError),
        customActions: [
          {
            action: this.retryUpload,
            icon: "fa-redo-alt",
            label: "Retry Upload",
          },
        ],
      })

      this.createFileListItem(file)

      // make the "has files" UI updates if need be
      this.showSmallZone()
      if (!this.hasDocumentListComponentOutlet) {
        this.updateFileStatus(file, FileStatus.ERROR, fullError)
        this.addErrorTag(file, fullError)
        if (fullError?.retryable) {
          this.addRetryButton(file)
        }
        this.addRemoveButton(file)
        this.showFileList()
      }

      // if there is a least one successful file, enable the submit
      if (this.hasSuccessfulUpload() && !this.isUploading()) {
        this.enableSubmitButton()
      }
    })

    if (!this.hasCustomIconValue) {
      this.fileDropTarget.addEventListener("dragenter", () => {
        this.showUploadFolder()
      })

      this.fileDropTarget.addEventListener("dragleave", () => {
        this.showClosedFolder()
      })

      this.fileDropTarget.addEventListener("mouseenter", () => {
        this.showFolderOpen()
      })

      this.fileDropTarget.addEventListener("mouseleave", () => {
        this.showClosedFolder()
      })
    }
  }

  disableSubmitButton() {
    if (this.formSubmitButton && !this.eventsOnlyValue) {
      this.formSubmitButton.disabled = true
      this.formSubmitButton.classList.add("disabled")
    }
    this.dispatch("disabled-doc-upload-submit")
  }

  enableSubmitButton() {
    if (this.formSubmitButton && !this.eventsOnlyValue) {
      this.formSubmitButton.disabled = false
      this.formSubmitButton.classList.remove("disabled")
    }
    this.dispatch("enabled-doc-upload-submit")
    window.dispatchEvent(
      new CustomEvent(EVENT_SUBMIT_ENABLED, {
        detail: {
          container: this.containerTarget,
          file_length: this.dropZone.files.length,
        },
      }),
    )
  }

  showUploadFolder() {
    this.folderIconTarget.classList.remove("fa-folder", "fa-folder-open")
    this.folderIconTarget.classList.add("fa-folder-upload")
  }

  showFolderOpen() {
    this.folderIconTarget.classList.remove("fa-folder")
    this.folderIconTarget.classList.add("fa-folder-open")
  }

  showClosedFolder() {
    this.folderIconTarget.classList.remove("fa-folder-open")
    this.folderIconTarget.classList.add("fa-folder")
  }

  createFileListItem(file) {
    const id = getFileId(file)
    let listItemToReplace

    if (file.retryId != null) {
      listItemToReplace = document.getElementById(file.retryId)
    } else {
      listItemToReplace = document.getElementById(id)
    }

    const loadingElement = document.createElement("div")
    loadingElement.id = id
    let wrapperDivClasses = ""
    loadingElement.classList.add("max-w-full")
    if (this.usedInAttachmentValue) {
      wrapperDivClasses = "flex gap-2 items-center py-2 px-3 border border-solid border-base rounded-full"
    } else {
      loadingElement.classList.add("w-full")
      wrapperDivClasses = "flex-row items-center cursor-pointer"
    }

    loadingElement.innerHTML = `
      <div class="${wrapperDivClasses}">
        <div id="${id}-name-status" class="flex items-center space-x-2 justify-start text-lg w-full">
          <i id="${id}-status" class="fad fa-spinner-third fa-spin text-gray-800 text-blue-500"></i>
          <span class="flex-grow body-text truncate">${file.name || file.upload.filename}</span>
          <div id="${id}-actions" class="flex space-x-2"></div>
        </div>
      </div>
          `

    if (this.fileListItemDataAttributesValue) {
      Object.entries(this.fileListItemDataAttributesValue).forEach(([key, value]) => {
        loadingElement.setAttribute(`data-${key}`, value)
      })
    }

    if (listItemToReplace) {
      listItemToReplace.replaceWith(loadingElement)
    } else {
      this.fileListTarget.appendChild(loadingElement)
    }
  }

  retryUpload = (file) => {
    file.retryId = getFileId(file)
    this.dropZone.emit("addedfiles", [file])
  }

  addRetryButton(file) {
    const id = getFileId(file)
    const retryButton = document.createElement("button")
    retryButton.type = "button"
    retryButton.addEventListener("click", (e: Event) => {
      if (this.dontBubbleActionButtonClickEventsValue) {
        e.stopPropagation()
      }
      this.retryUpload(file)
    })

    retryButton.innerHTML = `
      <div class="mx-2 border-r px-3 border-base text-[14px] text-purple-500 font-medium h-5">
        <i class="fas fa-redo-alt"></i>
        <span>Retry Upload</span>
      </div> 
    `

    const actionButtonGroup = document.getElementById(`${id}-actions`)
    actionButtonGroup?.appendChild(retryButton)
  }

  addRemoveButton(file) {
    const removeButton = document.createElement("button")
    removeButton.type = "button"
    removeButton.innerHTML = "<i class='fas fa-times'></i>"
    removeButton.classList.add("text-gray-500", "cursor-pointer")
    removeButton["data-dz-remove"] = true
    removeButton.addEventListener("click", (e: Event) => {
      if (this.dontBubbleActionButtonClickEventsValue) {
        e.stopPropagation()
      }
      this.removeFile(file)
    })

    const id = getFileId(file)
    const actionButtonGroup = document.getElementById(`${id}-actions`)
    actionButtonGroup?.appendChild(removeButton)
  }

  getErrorTag = (error) => {
    const ERROR_TYPE_TO_TAG = {
      [ErrorType.FILE_TOO_LARGE]: `Max size is ${this.data.get("max-file-size")}MB`,
      [ErrorType.UNSUPPORTED_FILE_TYPE]: "Unsupported file",
      [ErrorType.SERVER_ERROR]: "Upload failed",
      [ErrorType.UNKNOWN_ERROR]: "Upload failed",
      [ErrorType.EMPTY_FILE]: "File is empty",
    }

    return ERROR_TYPE_TO_TAG[error?.type] || "Upload failed"
  }

  addErrorTag(file, error) {
    const id = getFileId(file)
    const fileActionsElement = document.getElementById(`${id}-actions`)

    const errorTag = document.createElement("div")
    errorTag.classList.add("text-right", "min-w-[140px]")
    errorTag.innerHTML = `
      <span class="bg-gray-100 px-3 py-2 rounded-lg body-text h-5 text-[12px] font-medium">
        ${this.getErrorTag(error)}
      </span>
    `
    if (fileActionsElement) {
      fileActionsElement.appendChild(errorTag)
    }
  }

  updateFileStatus(file, status: FileStatus, error = null) {
    const id = getFileId(file)
    const statusElement = document.getElementById(`${id}-status`)
    switch (status) {
      case FileStatus.SUCCESS: {
        statusElement.classList.remove("fa-spinner-third", "fa-spin", "text-blue-500")
        if (this.usedInAttachmentValue) {
          statusElement.classList.add("fa-file", "text-green-500", "text-sm")
        } else {
          statusElement.classList.add("fa-check", "text-green-500")
        }
        break
      }
      case FileStatus.ERROR: {
        let styles = error?.retryable ? RETRYABLE_FILE_STYLES : NON_RETRYABLE_FILE_STYLES

        statusElement.classList.remove("fa-spinner-third", "fa-spin", "text-blue-500")
        statusElement.classList.add(styles.icon, styles.color)
        break
      }
    }
  }

  // transform dropzone error into our custom format
  transformError(error: string | Error) {
    if (typeof error === "string") {
      let type: ErrorType | undefined
      if (error === "You can't upload files of this type.") {
        type = ErrorType.UNSUPPORTED_FILE_TYPE
      } else if (error.startsWith("File is too big")) {
        type = ErrorType.FILE_TOO_LARGE
      } else if (error.startsWith("Error creating Blob for")) {
        type = ErrorType.SERVER_ERROR
      } else if (error.startsWith("File contains no content")) {
        type = ErrorType.EMPTY_FILE
      } else {
        type = ErrorType.UNKNOWN_ERROR
      }

      return {
        message: error,
        type,
        retryable: RETRYABLE_ERRORS.has(type),
      }
    } else {
      const type = (error as any).type || ErrorType.UNKNOWN_ERROR

      return {
        ...error,
        type,
        retryable: RETRYABLE_ERRORS.has(type),
      }
    }
  }

  // add a hidden input to the form to store the file name
  addHiddenFileNameInput(file) {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "file_name[]"
    input.value = file.name
    input.multiple = true
    input.id = `${getFileId(file)}-name`
    this.fileListTarget.appendChild(input)
  }

  // add a hidden input to the form to distinguish if the file was uploaded manually or through the drag and drop
  addHiddenTriggerInput(file) {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "upload_method[]"
    input.value = file.upload_method || "manual"
    input.multiple = true
    input.id = `${getFileId(file)}-trigger`
    this.fileListTarget.appendChild(input)
  }

  hasSuccessfulUpload() {
    return this.dropZone.getFilesWithStatus("success").length > 0
  }

  isUploading() {
    return this.dropZone.getUploadingFiles().length > 0
  }

  removeFile = (file) => {
    // remove the file from Dropzone
    this.dropZone.removeFile(file)

    if (this.cacheFilesValue) {
      this.removeFileFromCache(file)
    }

    // remove the corresponding hidden file inputs and list item
    this.removeHiddenFileInput(file)
    this.removeHiddenFileNameInput(file)
    this.removeFileFromList(file)
    this.removeHiddenTriggerInput(file)

    // check if there are any files left to change UI
    // if the only files that are left are invalid, disable submit button
    if (this.dropZone.files.length > 0 && !this.hasSuccessfulUpload()) {
      this.disableSubmitButton()
      // if there are no files at all, then show the base uploader
    } else if (this.dropZone.files.length === 0) {
      this.showLargeZone()
      if (!this.usedInAttachmentValue) {
        this.hideFileList()
      }
      if (this.requiredValue) this.disableSubmitButton()
    }
    window.dispatchEvent(
      new CustomEvent<FileChangeEventDetail & Record<string, any>>(EVENT_FILE_REMOVED, {
        detail: {
          container: this.containerTarget,
          file,
          file_length: this.dropZone.files.length,
        },
      }),
    )
  }

  removeHiddenFileInput(file) {
    const id = getFileId(file)
    const hiddenFileInput = document.getElementById(`${id}-file-input`)
    removeElement(hiddenFileInput)
  }

  removeHiddenFileNameInput(file) {
    const id = getFileId(file)
    const hiddenNameInput = document.getElementById(`${id}-name`)
    removeElement(hiddenNameInput)
  }

  removeHiddenTriggerInput(file) {
    const id = getFileId(file)
    const hiddenTriggerInput = document.getElementById(`${id}-trigger`)
    removeElement(hiddenTriggerInput)
  }

  removeFileFromList(file) {
    const id = getFileId(file)
    const fileListItem = document.getElementById(id)
    removeElement(fileListItem)
  }

  showFileList() {
    this.fileListTarget.classList.remove("hidden")
  }

  hideFileList() {
    this.fileListTarget.classList.add("hidden")
  }

  showSmallZone() {
    if (this.allowMultipleFilesValue) {
      this.smallZoneTarget.classList.remove("hidden")
    }
    this.largeZoneTarget.classList.add("hidden")

    this.removeLargeDzMessage()
  }

  showLargeZone() {
    this.smallZoneTarget.classList.add("hidden")
    this.largeZoneTarget.classList.remove("hidden")

    this.addLargeDzMessage()
  }

  // needed because dropzone looks for the first element that has class "dz-message"
  // we want the to only have this enabled for the large zone when it is showing
  removeLargeDzMessage() {
    const largeZoneMsg = this.largeZoneMessage
    largeZoneMsg?.classList.remove("dz-message")
  }

  addLargeDzMessage() {
    const largeZoneMsg = this.largeZoneMessage
    largeZoneMsg?.classList.add("dz-message")
  }

  get largeZoneMessage(): HTMLElement | undefined {
    return findElement(this.largeZoneTarget, "#large-zone-msg")
  }

  getIconForFile(fileName: string) {
    const fileExtension = fileName.split(".").pop()
    const correspondingIcon = FILE_TYPE_TO_ICON[fileExtension]

    return correspondingIcon || "fa-file"
  }

  setFileIcon(fileName: string) {
    const correspondingIcon = this.getIconForFile(fileName)

    this.fileIconTarget.classList.remove(...Object.values(FILE_TYPE_TO_ICON))
    this.fileIconTarget.classList.add(correspondingIcon)
  }

  // cache utils
  removeFileFromCache(file): void {
    const files = getSessionStorageValue(this.sessionStorageKeyValue)

    if (files[file.upload.uuid]) {
      delete files[file.upload.uuid]
      setSessionStorageValue(this.sessionStorageKeyValue, files)
    }
  }

  addFileToCache(file): void {
    const files = getSessionStorageValue(this.sessionStorageKeyValue)
    const id = getFileId(file)

    if (!files[id]) {
      files[id] = file
      setSessionStorageValue(this.sessionStorageKeyValue, files)
    } else {
      createHiddenInput(file, this.fileDropTarget.querySelector("input[id^='dropzone-input_']"))
    }
  }

  updateProvidedFileList = ({ customActions, file, status, statusLabel }: Partial<UpsertFileParams>) => {
    if (this.hasDocumentListComponentOutlet) {
      this.documentListComponentOutlets.forEach((outletController) => {
        const componentOutletIsWatching = outletController.element.dataset.docuploadDocumentListComponentOutletForId

        if (!componentOutletIsWatching) {
          console.error(
            "documentListComponentOutlet is missing the",
            "data-docupload-document-list-component-outlet-for-id attribute",
          )
          return
        }

        if (componentOutletIsWatching !== this.element.id) return

        outletController.upsertFile({
          file,
          customActions: customActions || [],
          onRemove: this.removeFile,
          status,
          statusLabel,
          uniqueId: file.upload.uuid,
        })
      })
    }
  }
}
